@import url('https://fonts.googleapis.com/css2?family=Alexandria&family=Fraunces:opsz,wght@9..144,700&display=swap');

* {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body {
	height: 100vh !important;
	margin: 0;
	padding: 0;
	display: flex !important;
	flex-direction: column !important;
}

#footer {
	margin-top: auto !important;
}

a {
	text-decoration: none; /* no underline */
	/*color: black;*/
}
sup {
	font-family: 'Alexandria', sans-serif;
	font-size: xx-large;
}

.bold {
	font-weight: bold;
}

ul.faq, ol.faq, div.faq {
	margin: 16px;
}

ol.faq li {
	padding-top: 8px;
}

li {
	font-family: "Roboto", "sans-serif";
	margin-top: 8px;
}
li.arrow-right {
	list-style: "➢ ";
	margin-left: 32px;
	margin-bottom: 8px;
}
li.circle {
	list-style: "● ";
	margin-left: 16px;
}

li.white-circle {
	list-style: "○ ";
	margin-left: 32px;
}

li.dash {
	list-style: "- ";
	margin-left: 32px;
}

li.latin {
	list-style-type: upper-latin;
	margin-left: 32px;
}

li.validate {
	list-style: "✓ ";
	margin-left: 32px;
}

.variant-h6 {
	font-size: 20px;
}

.faq-main {
	padding-left: 16px;
	padding-right: 16px;
}

.carousel {
	height: 100vh;
	width: 100vw;
	object-fit: cover;
}

.roboto {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ml-32 {
	margin-left: 32px;
}

.p-2 {
	padding: 2px;
}

h3.title {
	font-size: x-large;
}

.glass {
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
}

/*MAP ON LIST ANNOUNCEMENT*/
.MuiAccordionSummary-expandIconWrapper{
	transform: rotate(0deg)!important;
}

.fade-in {
	animation: fadeIn 1500ms;
}

.admin {
	display: flex;
}
.admin .home {
	min-height: 80vh;
}
.admin .sidebar {
	min-height: 100%;
	width: 275px;
	min-width: 250px;
	padding: 20px 20px;
	box-shadow: 0 0 6px hsl(206, 9%, 85%);
}
.admin .link{
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 30px;
}
.admin .link:hover{
	background-color: #e8f5fe;
	color: #7db9e3;
	transition: color 100ms ease-out;
}
.admin .link > h2{
	font-weight: 700;
	font-size: 16px;
	margin-right: 20px;
}
.admin .link > .MuiSvgIcon-root {
	padding: 12px;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media only screen and (max-width: 600px) {
	sup {
		font-size: large;
	}
}